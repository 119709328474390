/* Sezione BANNER */
.banner-container {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 15%;
}

.banner-container .banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 2em;
    text-align: center;
    color: var(--white-100)
}

/* Sezione PRESENTAZIONE */
.home-presentation {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
}

.home-presentation .presentation-text {
    margin: 1em 0;
    font-size: 1.25em;
    font-weight: 600;
    text-align: center;
}

/* Sezione SERVIZI */
.servizi-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    padding: .5em 2.25em;
}

/* Gestione Mobile */
@media(max-width: 992px) {
    .banner-container {
        background-position: center;
    }
}

@media(max-width: 767px) {
    .servizi-container {
        justify-content: space-evenly;
    }
}