header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(196, 154, 34, 0.9);
    z-index: 9999;
}

header .header-desktop {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1em 1.5em;
    box-sizing: border-box;
}

header .header-desktop .brand {
    margin: 0 3em 0 0;
    height: 4em;
    width: auto;
}

header .header-desktop .flags-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    column-gap: 1em;
}

header .header-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

header .header-mobile .brand {
    margin: 0;
    height: 3em;
}

header .header-mobile .mobile-logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.5em;
    box-sizing: border-box;
}

header .header-mobile .mobile-menu-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: .75em;
    box-sizing: border-box;
    row-gap: .75em;
}

header .menu-items {
    color: var(--white-75);
    font-size: 1.25em;
    cursor: pointer;
}

header .menu-items:hover {
    font-weight: bold;
}

header .header-desktop .menu-items {
    margin: 0 2em 0 0;
}