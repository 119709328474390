:root {
    /* colors */
    --white-75: #EEEEEE;
    --white-80: #EFEFEF;
    --white-100: #FFFFFF;
    --black-100: #000000;
    --primary-gray: #6A6A6A;
    --gray: rgba(126, 133, 142, 1);
    --gray-22: #383838;
    --gray-51: #828282;
    --light-gray: #d4d4d4;
    --anatolia-gold: #c49a22
}