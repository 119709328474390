footer {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5em 1.5em 0 1.5em;
    box-sizing: border-box;
    background-color: var(--black-100);
    color: var(--anatolia-gold)
}

footer .footer-sections-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
}

footer .footer-sections {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

footer .footer-sections iframe {
    width: 600px;
    height: 450px;
}

footer .footer-disclaimer {
    width: 100%;
    text-align: center;
    padding: 1.5em 0 .75em;
    box-sizing: border-box;
    font-size: .9em;
}

@media(max-width: 767px) {
    footer .footer-sections iframe {
        width: 80vw;
        height: 40vh;
    }

    footer .footer-disclaimer {
        font-size: .75em
    }
}