.card-container {
    display: flex;
    flex-direction: column;
    width: 280px;
    border: 1px solid var(--light-gray)
}

.card-container img {
    border-bottom: solid 1px var(--light-gray);
}

.card-container .card-title {
    font-size: 1.25em;
    text-align: center;
    margin: .25em;
}

.card-container .card-body {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1em;
    margin: .25em;
    padding: .75em;
}

.card-container .card-button {
    cursor: pointer;
    background-color: var(--anatolia-gold);
    color: var(--white-100);
    margin: 1.25em;
    padding: .75em;
    text-transform: uppercase;
    outline: none;
    border: none;
}

.card-container .card-button:hover, .card-container .card-button:active {
    background-color: var(--black-100);
    color: var(--anatolia-gold);
    font-weight: bold;
}