section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

section .section-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: .75em .25em;
    font-size: 2.5em;
    color: var(--white-100);
    background-color: var(--anatolia-gold);
    text-transform: uppercase;
}